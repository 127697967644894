import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Error } from '@/components/Error';
import { Layout } from '@/components/Layout';
import { Spinner } from '@/components/Loading';
import { usePromise, useTheme } from '@/hooks';
import { formatDate } from '@/lib/dates';

import { Conferences } from '../components';
import { getUserConferences } from '../dal';
import styles from './ConferencesPage.module.scss';

type Props = {
  username: string;
};

const ConferencesPage = ({ username }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const [get, { data: conferences, isLoading: loading, error }] =
    usePromise(getUserConferences);

  useEffect(() => {
    get();
  }, [get]);

  return (
    <Layout username={username}>
      {loading && <Spinner size="medium" />}
      {error && <Error text={error} />}
      {conferences && conferences.length ? (
        <div className={`${styles[theme]} ${styles.container}`}>
          <div className={styles.title}>{t('My conferences')}</div>
          <Conferences
            conferences={conferences.map((conference) => ({
              ...conference,
              ddtm: formatDate('DD/MM/YYYY HH:MM', conference.ddtm),
            }))}
          />
        </div>
      ) : (
        <div className={`${styles.message} ${styles[theme]}`}>
          {t('No conferences')}
        </div>
      )}
    </Layout>
  );
};

export default ConferencesPage;
