import { CurrentUser } from '@/types/auth';

import { dal } from '../api';

const login = (username: string, password: string) =>
  dal.post<{ user: CurrentUser }>(
    `/api/auth/login`,
    { username, password },
    { cancelable: true }
  );

const logout = () => dal.post<Promise<void>>(`/api/auth/logout`);

export { login, logout };
