import React, { useCallback, useEffect, useState } from 'react';

import { usePromise, useTheme } from '@/hooks';

import { getVersion } from './dal';
import styles from './Version.module.scss';

const Version: React.FC = () => {
  // 10 pierwszych znaków z hashu commita oraz data wydania;
  const [version, setVersion] = useState<string>('Not defined');

  const { theme } = useTheme();

  const handleSetVersion = useCallback(
    ({ version }: { version: string }) => setVersion(version),
    []
  );

  const [get] = usePromise(getVersion, handleSetVersion);

  useEffect(() => {
    get();
  }, [get]);

  return (
    <small className={`${styles.version} ${styles[theme]}`}>{version}</small>
  );
};

export default Version;
