import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { usePromise } from '@/hooks';
import routes from '@/routes';
import { logout, useStoreUser } from '@/services/auth';

const JoinConferencePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { onLogout } = useStoreUser();

  const [doLogout] = usePromise(logout, onLogout);

  const redirectToConference = (conferenceId: string) => {
    navigate(routes.CONFERENCE_PAGE(conferenceId));
  };

  const redirectToAllConferences = () => {
    navigate(routes.CONFERENCES_PAGE);
  };

  return (
    <div>
      <h2>
        {t('Conference')}: {id}
      </h2>
      <button onClick={() => redirectToConference(id || '')}>
        {t('Join to conference')}
      </button>
      <button onClick={redirectToAllConferences}>
        {t('Display all your conferences')}
      </button>
      <button onClick={doLogout}>{t('Log out')}</button>
    </div>
  );
};

export default JoinConferencePage;
