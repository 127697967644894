import React, { useEffect, useState } from 'react';

import styles from './LoadingOverlay.module.scss';
import Spinner from './Spinner';

type Props = {
  loading?: boolean;
  spinnerSize?: 'small' | 'medium' | 'large';
  delayMS?: number;
  text?: string;
  children: React.ReactNode;
};

const LoadingOverlay: React.FC<Props> = ({
  loading = false,
  spinnerSize = 'medium',
  delayMS = 200,
  text,
  children,
}) => {
  const [indicateLoading, setIndicateLoading] = useState<boolean>(false);

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>;
    if (!loading) {
      setIndicateLoading(false);
      return;
    }
    if (delayMS === 0) {
      setIndicateLoading(true);
    } else {
      timeout = setTimeout(() => setIndicateLoading(true), delayMS);
    }
    return () => {
      clearInterval(timeout);
    };
  }, [loading, delayMS]);

  return (
    <div className={`${styles.container} ${indicateLoading && styles.loading}`}>
      {children}
      {indicateLoading && (
        <div className={styles.spinner}>
          <Spinner size={spinnerSize} />
          {text && <p className={styles.text}>{text}</p>}
        </div>
      )}
    </div>
  );
};

export default LoadingOverlay;
