import type { DeviceStatusChanged, ParticipantType } from '@/lib/videoStream/d';
import { dal } from '@/services/api';
import { User } from '@/types/auth';
import { IpCamera, VideoSession } from '@/types/videoConference';

const getSessionToken = (
  sessionId: string,
  participantType: ParticipantType = 'user'
) =>
  dal.post<{ token: string }>(
    `/api/sessions/${sessionId}/authorize`,
    {
      type: participantType,
    },
    { cancelable: true }
  );

const getSessionCameras = (sessionId: string) =>
  dal.get<{ cameras: IpCamera[] }>(`/api/ip-cameras/${sessionId}`);

const inviteUser = ({
  userId,
  email,
  sessionId,
  invitation,
}: {
  userId: number;
  email: string;
  sessionId: string;
  invitation: string;
}) =>
  dal.post<{ userId: number; sent: boolean }>(`/api/emails/invite`, {
    userId,
    email,
    sessionId,
    invitation,
  });

const publishCamera = (
  sessionId: string,
  cameraId: string,
  publisherId: string
) =>
  dal.post(
    `/api/ip-cameras/${sessionId}/publish`,
    { cameraId, publisherId },
    { cancelable: true }
  );

const unPublishCamera = (sessionId: string, cameraId: string) =>
  dal.post(
    `/api/ip-cameras/${sessionId}/unPublish`,
    { cameraId },
    { cancelable: true }
  );

const deviceStatusChanged = (event: DeviceStatusChanged) =>
  dal.post(`/api/sessions/webhook`, {
    ...event,
    clientData: JSON.stringify(event.clientData),
  });

const kickUser = (sessionId: string, userId: number) =>
  dal.delete(`/api/sessions/${sessionId}/connections/${userId}`);

const getUserConferences = () => dal.get<VideoSession[]>('/api/sessions');

const getConferenceName = (sessionId: string) =>
  dal.get<{ name: string | undefined }>(`api/sessions/${sessionId}`);

const getNotInvitedUsers = (sessionId: string) =>
  dal.get<{ users: User[] }>(`api/sessions/users/${sessionId}`);

export {
  deviceStatusChanged,
  getConferenceName,
  getNotInvitedUsers,
  getSessionCameras,
  getSessionToken,
  getUserConferences,
  inviteUser,
  kickUser,
  publishCamera,
  unPublishCamera,
};
