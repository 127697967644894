import {
  Connection,
  ExceptionEvent,
  OpenVidu,
  Publisher,
  Session,
  StreamEvent,
  Subscriber,
} from 'openvidu-browser';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DeviceStatusChanged, ParticipantType, Signal } from '../d';

type Props = {
  publishIpCamera?: (
    sessionId: string,
    cameraId: string,
    publisherId: string
  ) => void;
  unPublishIpCamera?: (sessionId: string, cameraId: string) => void;
  onDeviceStatusChange?: (someProperties: DeviceStatusChanged) => void; // wywołane po włączeniu / wyłączeniu kamery lub mikrofonu
  onUserKick?: (sessionId: string, userId: number) => void;
};

const useVideoSession = ({
  publishIpCamera,
  unPublishIpCamera,
  onDeviceStatusChange,
  onUserKick,
}: Props) => {
  const [session, setSession] = useState<Session>();
  const [sessionScreen, setSessionScreen] = useState<Session>();
  const [mainStreamManager, setMainStreamManager] = useState<any>(undefined);
  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [voiceEnabled, setVoiceEnabled] = useState<boolean>(true);
  const [cameraEnabled, setCameraEnabled] = useState<boolean>(true);
  const [isSharingScreen, setIsSharingScreen] = useState<boolean>(false);
  const [activeSpeakers, setActiveSpeakers] = useState<string[]>([]);
  const [publishedIpCam, setPublishedIpCam] = useState<Subscriber>();
  const [currentDurationTime, setCurrentDurationTime] = useState<
    string | undefined
  >();

  const publisherRef = useRef<Publisher | undefined>(undefined);

  useEffect(() => {
    const currentDurationTime = setInterval(() => {
      if (session) {
        const date = new Date(
          new Date().getTime() - session?.connection.creationTime
        );

        date.setHours(date.getHours() - 1);

        setCurrentDurationTime(
          date.toTimeString().slice(0, 8)[1] === '0'
            ? date.toTimeString().slice(3, 8)
            : date.toTimeString().slice(0, 8)
        );
      }
    }, 1000);

    return () => {
      return clearInterval(currentDurationTime);
    };
  }, [session]);

  const setPublisher = (publisher: Publisher | undefined) =>
    (publisherRef.current = publisher);

  const leaveSession = useCallback(() => {
    if (sessionScreen) {
      sessionScreen.disconnect();
    }
    if (session) {
      session.disconnect();
    }
    setSessionScreen(undefined);
    setSession(undefined);
    setSubscribers([]);
    setMainStreamManager(undefined);
    setPublisher(undefined);
    setVoiceEnabled(true);
    setCameraEnabled(true);
    setIsSharingScreen(false);
    setActiveSpeakers([]);
    setPublishedIpCam(undefined);
  }, [session, sessionScreen]);

  useEffect(() => {
    const onbeforeunload = () => leaveSession();

    window.addEventListener('beforeunload', onbeforeunload);

    return () => window.removeEventListener('beforeunload', onbeforeunload);
  }, [leaveSession]);

  const handleMainVideoStream = (stream: any) => {
    if (mainStreamManager !== stream) {
      setMainStreamManager(stream);
    }
  };

  const deleteSubscriber = (streamManager: any) => {
    setSubscribers((prev) =>
      prev.filter((subscriber) => subscriber !== streamManager)
    );
  };

  const joinSession = (
    token: string,
    { id, username }: { id: number; username: string }
  ) => {
    const OV = new OpenVidu();
    const newSession = OV.initSession();

    const defaultConfig = {
      audioSource: undefined, // The source of audio. If undefined default microphone
      videoSource: undefined, // The source of video. If undefined default webcam
      publishAudio: true, // Whether you want to start publishing with your audio unmuted or not
      publishVideo: true, // Whether you want to start publishing with your video enabled or not
      resolution: '640x480', // The resolution of your video
      frameRate: 30, // The frame rate of your video
      insertMode: 'APPEND', // How the video is inserted in the target element 'video-container'
      mirror: true, // Whether to mirror your local video or not
    };

    newSession.on('streamCreated', (event: StreamEvent) => {
      const subscriber = newSession.subscribe(event.stream, undefined);

      event.stream.typeOfVideo === 'IPCAM'
        ? setPublishedIpCam(subscriber)
        : setSubscribers((prev) => [...prev, subscriber]);
    });

    newSession.on('streamDestroyed', (event: StreamEvent) => {
      event.stream.typeOfVideo === 'IPCAM'
        ? setPublishedIpCam(undefined)
        : deleteSubscriber(event.stream.streamManager);
    });

    newSession.on('sessionDisconnected', () => {
      // Kiedy użytkownik próbuje się połączyć do tej samej sesji z innego okna przeglądarki to usuwamy obecne połączenie.
      leaveSession();
    });

    newSession.on('streamPropertyChanged', (event) => {
      const stream = event.stream;
      const connectionId = stream.connection.connectionId;
      const { changedProperty } = event;
      if (
        changedProperty === 'audioActive' &&
        connectionId !== publisherRef.current?.id
      ) {
        setSubscribers((prev) =>
          prev.map((sub) => {
            if (sub.stream.connection.connectionId === connectionId) {
              sub.stream = stream;
            }
            return sub;
          })
        );
      }
    });

    newSession.on('publisherStartSpeaking', (event) => {
      const { connectionId } = event.connection;
      setActiveSpeakers((prev) => [...prev, connectionId]);
    });

    newSession.on('publisherStopSpeaking', (event) => {
      const { connectionId } = event.connection;
      setActiveSpeakers((prev) => prev.filter((id) => id !== connectionId));
    });

    newSession.on('exception', (exception: ExceptionEvent) => {
      console.warn(exception);
    });

    newSession.on('signal', (event) => {
      const singal = event as Signal;
      if (
        singal.data === 'signal:mute-user' &&
        publisherRef.current?.stream.audioActive
      ) {
        toggleVoice();
      }
    });

    // TODO ENFORCE ClientData type as metadata type
    return newSession
      .connect(token, {
        userId: id,
        name: username,
        type: 'user',
        mic: voiceEnabled,
        cam: cameraEnabled,
      })
      .then(() => {
        if (!OV) {
          throw 'OpenVindu manager is null';
        }
        return OV.initPublisherAsync(undefined, defaultConfig);
      })
      .then((publisher) => {
        newSession.publish(publisher);
        setSession(newSession);
        setMainStreamManager(publisher);
        setPublisher(publisher);
      })
      .catch((e) => {
        if (e.name === 'INPUT_VIDEO_DEVICE_NOT_FOUND') {
          return OV.initPublisherAsync(undefined, {
            ...defaultConfig,
            videoSource: false,
            publishVideo: false,
          }).then((publisher) => {
            newSession.publish(publisher);
            setSession(newSession);
            setMainStreamManager(publisher);
            setPublisher(publisher);
          });
        }
      });
  };

  const getNicknameTag = (streamManager: Publisher | Subscriber): string =>
    JSON.parse(streamManager.stream.connection.data).name;

  const getTypeTag = (
    streamManager: Publisher | Subscriber
  ): ParticipantType => {
    return JSON.parse(streamManager.stream.connection.data)?.type;
  };

  const getUserId = (streamManager: Publisher | Subscriber): number =>
    JSON.parse(streamManager.stream.connection.data).userId;

  const getPublishedIpCameraId = (
    streamManager: Publisher | Subscriber
  ): string => JSON.parse(streamManager.stream.connection.data).cameraId;

  const toggleVoice = () => {
    // local method
    if (publisherRef.current) {
      publisherRef.current.publishAudio(!voiceEnabled);
      setVoiceEnabled((prev) => !prev);
      onDeviceStatusChange?.({
        event: 'microphoneStatusChanged',
        clientData: {
          userId: getUserId(publisherRef.current),
          name: getNicknameTag(publisherRef.current),
          type: 'user',
          mic: !voiceEnabled,
          cam: cameraEnabled,
        },
        sessionId: session?.sessionId,
        connectionId: session?.connection.connectionId,
      });
    }
  };

  const handleMuteUser = (connection?: Connection) => () => {
    const data: Signal['data'] = 'signal:mute-user';
    if (connection) {
      return session?.signal({
        data,
        to: [connection],
      });
    }

    return Promise.reject();
  };

  const toggleCamera = () => {
    if (publisherRef.current) {
      publisherRef.current.publishVideo(!cameraEnabled);
      setCameraEnabled((prev) => !prev);
      onDeviceStatusChange?.({
        event: 'cameraStatusChanged',
        clientData: {
          userId: getUserId(publisherRef.current),
          name: getNicknameTag(publisherRef.current),
          type: 'user',
          mic: voiceEnabled,
          cam: !cameraEnabled,
        },
        sessionId: session?.sessionId,
        connectionId: session?.connection.connectionId,
      });
    }
  };
  const shareScreen = (token: string) => {
    const OV = new OpenVidu();
    const newSession = OV.initSession();

    const screenPublisher = OV.initPublisher(undefined, {
      videoSource: 'screen',
      audioSource: undefined,
      publishAudio: false,
    });

    // TODO ENFORCE ClientData type as metadata type
    newSession
      ?.connect(token, {
        userId: publisherRef.current && getUserId(publisherRef.current),
        name:
          publisherRef.current &&
          `${getNicknameTag(publisherRef.current)} (Ekran)`,
        type: 'screen',
      })
      .then(() => {
        screenPublisher.once('accessAllowed', () => {
          screenPublisher.stream
            .getMediaStream()
            .getVideoTracks()[0]
            .addEventListener('ended', () => stopSharingScreen(newSession));
          newSession.publish(screenPublisher);
          setSessionScreen(newSession);
          setIsSharingScreen(true);
        });
        screenPublisher.once('accessDenied', () => {
          alert('Udostępnienie ekranu: Brak uprawnień');
        });
      })
      .catch((error) => {
        console.warn(
          'There was an error connecting to the session:',
          error.code,
          error.message
        );
      });
  };

  // domyślnie sessionScreen, ale dodałem parametr, bo w przypadku gdy user klika "Stop sharing screen" natywny dla danej przeglądarki
  // to wywołanie stopSharingScreen działa w innym kontekście i nie wykrywało sessionScreen.
  const stopSharingScreen = (sessionToStop = sessionScreen) => {
    sessionToStop?.disconnect();
    setIsSharingScreen(false);
  };

  const onSelectIpCamera = (cameraId: string) => {
    onDeselectIpCamera();

    if (publisherRef.current && session?.sessionId) {
      publishIpCamera?.(
        session?.sessionId,
        cameraId,
        publisherRef.current.stream.connection.connectionId
      );
    }
  };

  const onDeselectIpCamera = () => {
    if (publishedIpCam) {
      const previousCameraId = JSON.parse(
        publishedIpCam.stream.connection.data
      ).cameraId;

      if (session?.sessionId && previousCameraId) {
        unPublishIpCamera?.(session.sessionId, previousCameraId);
      }
    }
  };

  const mapStreamManagersToParticipants = (
    streamManagers: (Publisher | Subscriber | undefined)[]
  ) =>
    streamManagers
      .filter((stream) => stream !== undefined)
      .map((streamManager) => {
        const type = getTypeTag(streamManager as Publisher | Subscriber);
        return {
          hasVideo: streamManager?.stream.hasVideo,
          name: getNicknameTag(streamManager as Publisher | Subscriber),
          type,
          streamManager: streamManager as Publisher | Subscriber,
          isSpeaking: activeSpeakers.includes(
            streamManager!.stream.connection.connectionId
          ),
          onCloseConnectionClick:
            type === 'IPCAM'
              ? onDeselectIpCamera
              : type === 'user'
              ? () =>
                  onUserKick?.(
                    session?.sessionId || '',
                    getUserId(streamManager as Publisher | Subscriber)
                  )
              : () => {},
          onMuteClick: handleMuteUser(streamManager?.stream.connection),
        };
      });

  const streamManagers = [publisherRef.current, ...subscribers, publishedIpCam];

  const participants = mapStreamManagersToParticipants(streamManagers);

  return {
    publisher: publisherRef.current,
    currentDurationTime,
    sessionId: session?.sessionId,
    participants,
    voiceEnabled,
    cameraEnabled,
    isSharingScreen,
    publishedIpCameraId:
      publishedIpCam && getPublishedIpCameraId(publishedIpCam),
    joinSession,
    leaveSession,
    handleMainVideoStream,
    toggleVoice,
    toggleCamera,
    shareScreen,
    stopSharingScreen,
    onSelectIpCamera,
  };
};

export default useVideoSession;
