import React from 'react';

import styles from './Error.module.scss';

type Props = {
  text: string;
};

const Error: React.FC<Props> = ({ text }) => (
  <div className={styles.error}>{text}</div>
);

export default Error;
