import React, { forwardRef, ForwardRefRenderFunction } from 'react';

import { useTheme } from '@/hooks';

import styles from './TextInput.module.scss';

type Props = React.ComponentPropsWithRef<'input'> & {
  disabled?: boolean;
  icon?: React.ReactNode;
  label?: string;
  validationMessage?: string;
};

const TextInput: ForwardRefRenderFunction<HTMLInputElement, Props> = (
  { label, validationMessage, icon, ...passThroughProps },
  ref
) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      {label && <span className={styles.label}>{label}</span>}
      <div className={styles.inputWrapper}>
        <input
          {...passThroughProps}
          id={label?.toString()}
          className={styles.input}
          ref={ref}
        />
        <div className={styles.icon}>{icon}</div>
      </div>
      {validationMessage && (
        <span className={styles.validationMessage}>{validationMessage}</span>
      )}
    </div>
  );
};

export default forwardRef(TextInput);
