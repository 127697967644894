import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PrimaryButton } from '@/components/Button';
import TextInput from '@/components/Input/TextInput';
import { Version } from '@/components/Version';
import { useTheme } from '@/hooks';

import styles from './LoginForm.module.scss';

type Props = {
  onSubmit: (username: string, password: string) => void;
};

type ErrorTypes = 'NO_USERNAME' | 'NO_PASSWORD';

const LoginForm: React.FC<Props> = ({ onSubmit }) => {
  const [error, setError] = useState<undefined | ErrorTypes>(undefined);

  const { t } = useTranslation();
  const { theme } = useTheme();

  const validateForm = (username: string, password: string) => {
    if (username.trim().length === 0) {
      setError('NO_USERNAME');
      return false;
    }

    if (password.trim().length === 0) {
      setError('NO_PASSWORD');
      return false;
    }

    setError(undefined);
    return true;
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = event.target as typeof event.target & {
      username: { value: string };
      password: { value: string };
    };

    if (validateForm(formData.username.value, formData.password.value)) {
      onSubmit(formData.username.value, formData.password.value);
    }
  };

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <form onSubmit={handleSubmit}>
        <div className={styles.form}>
          <div className={styles.title}>{t('Log in to video session')}</div>
          <div className={styles.inputsWrapper}>
            <TextInput
              type="text"
              name="username"
              label={t('Username')}
              validationMessage={
                error === 'NO_USERNAME'
                  ? t('Username field cannot be empty')
                  : ''
              }
            />
            <TextInput
              type="password"
              name="password"
              label={t('Password')}
              validationMessage={
                error === 'NO_PASSWORD'
                  ? t('Password field cannot be empty')
                  : ''
              }
            />
          </div>
          <PrimaryButton name="commit" type="submit">
            {t('Login')}
          </PrimaryButton>
          <Version />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
