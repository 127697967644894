import { useCallback, useReducer } from 'react';

type State<T> = {
  data?: T;
  isLoading: boolean;
  error?: string;
};

type Action<T> =
  | { type: 'start-loading' }
  | { type: 'success'; data: T }
  | { type: 'error'; error: string };

type Reducer<T> = (state: State<T>, action: Action<T>) => State<T>;

type PromiseCreator<T> = (...args: any[]) => Promise<T>;
export type PromiseInvoker = (...args: any[]) => void;

const reducer = <T>(state: State<T>, action: Action<T>): State<T> => {
  switch (action.type) {
    case 'start-loading':
      return { ...state, isLoading: true, data: undefined, error: undefined };

    case 'success':
      return { ...state, isLoading: false, data: action.data };

    case 'error':
      return { ...state, isLoading: false, error: action.error };
  }
};

const Ø = () => {};

const usePromise = <T>(
  creator: PromiseCreator<T>,
  onSuccess: (data: T) => void = Ø,
  onError: (error: string) => void = Ø
): [PromiseInvoker, State<T>] => {
  const [state, dispatch] = useReducer<Reducer<T>>(reducer, {
    isLoading: false,
  });

  const invoker = useCallback(
    (...args: any[]) => {
      dispatch({ type: 'start-loading' });

      creator(...args)
        .then((data) => {
          dispatch({ type: 'success', data });
          onSuccess(data);
        })
        .catch((error) => {
          dispatch({
            type: 'error',
            error: error?.message?.toString(),
          });

          onError(error?.message?.toString());
        });
    },
    [creator, onSuccess, onError]
  );

  return [invoker, state];
};

export default usePromise;
