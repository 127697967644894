const PhoneClosed = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.81679 15.7221L2.15781 14.0632C2.00318 13.9098 1.88228 13.7258 1.80287 13.523C1.72345 13.3202 1.68727 13.103 1.69665 12.8854C1.70602 12.6678 1.76073 12.4545 1.85728 12.2593C1.95384 12.0641 2.0901 11.8912 2.25735 11.7516C4.14391 10.2349 6.35994 9.18179 8.72739 8.67699C10.9126 8.18989 13.1782 8.18989 15.3633 8.67699C17.7405 9.18507 19.9647 10.246 21.8555 11.7738C22.0222 11.9129 22.1582 12.0852 22.2547 12.2797C22.3512 12.4742 22.4061 12.6867 22.416 12.9036C22.4259 13.1205 22.3905 13.3371 22.3121 13.5396C22.2337 13.7421 22.1139 13.9261 21.9606 14.0797L20.3016 15.7387C20.0358 16.0097 19.6806 16.1749 19.3021 16.2034C18.9237 16.2319 18.5477 16.1218 18.2444 15.8936C17.6435 15.4327 16.9923 15.0413 16.3034 14.7268C16.0311 14.6032 15.7999 14.4042 15.6372 14.1533C15.4745 13.9024 15.3871 13.6101 15.3854 13.3111V11.9065C13.2138 11.3093 10.9212 11.3092 8.74951 11.9065L8.74951 13.3111C8.74783 13.6101 8.66047 13.9024 8.49778 14.1533C8.33508 14.4042 8.10387 14.6032 7.83154 14.7268C7.1426 15.0413 6.49146 15.4327 5.89052 15.8936C5.584 16.1243 5.20337 16.2343 4.82102 16.2027C4.43867 16.1711 4.08127 16.0001 3.81679 15.7221Z"
      stroke="#15181E"
      strokeWidth="1.6"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default PhoneClosed;
