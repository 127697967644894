import React from 'react';

import { useTheme } from '@/hooks';

import styles from './Toolbar.module.scss';

type MiddleToolbar = {
  middle: React.ReactNode;
  type: 'middle';
};

type SidesToolbar = {
  left: React.ReactNode;
  right: React.ReactNode;
  type: 'sides';
};

type Props = MiddleToolbar | SidesToolbar;

const Toolbar = (props: Props) => {
  const { theme } = useTheme();

  if (props.type === 'middle') {
    return (
      <div className={`${styles.middleToolbar} ${styles[theme]}`}>
        <div>{props.middle}</div>
      </div>
    );
  }

  return (
    <div className={`${styles.sidesToolbar} ${styles[theme]}`}>
      <div>{props.left}</div>
      <div>{props.right}</div>
    </div>
  );
};

export default Toolbar;
