import React from 'react';

import { useTheme } from '@/hooks';

import styles from './IconButton.module.scss';

type Props = React.ComponentPropsWithRef<'button'> & {
  icon: React.ReactNode;
};

const IconButton: React.FC<Props> = ({ icon, ...passTroughProps }) => {
  const { theme } = useTheme();

  return (
    <button className={`${styles.box} ${styles[theme]}`} {...passTroughProps}>
      {icon}
    </button>
  );
};
export default IconButton;
