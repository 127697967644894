import { useTranslation } from 'react-i18next';

import { SecondaryButton } from '@/components/Button';
import { Menu, MenuButton, MenuItem } from '@/components/Menu';
import { useTheme } from '@/hooks';
import { useStoreUser } from '@/services/auth';
import { User } from '@/types/auth';

import styles from './UsersToInvite.module.scss';

type Props = {
  usersToInvite: User[];
  onClick: ({ email, userId }: { email: string; userId: number }) => void;
};

const UsersToInvite = ({ usersToInvite, onClick }: Props) => {
  const { t } = useTranslation();
  const { user } = useStoreUser();
  const { theme } = useTheme();

  return (
    <Menu
      direction="left"
      className={`${styles.menu} ${styles[theme]}`}
      menuButton={
        <MenuButton className={styles.menuButton}>
          <SecondaryButton>{t('Add other participants')}</SecondaryButton>
        </MenuButton>
      }
    >
      {usersToInvite.map(({ id, name, surname, login }) => (
        <MenuItem
          key={id}
          className={styles.menuItem}
          onClick={() =>
            user &&
            onClick({
              email: login,
              userId: id,
            })
          }
        >
          <div>
            <span>{name}</span>
            <span>{surname}</span>
          </div>
          <SecondaryButton>{t('Invite')}</SecondaryButton>
        </MenuItem>
      ))}
    </Menu>
  );
};

export default UsersToInvite;
