import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Error } from '@/components/Error';
import { LoadingOverlay } from '@/components/Loading';
import { usePromise, useTheme } from '@/hooks';
import { login, useStoreUser } from '@/services/auth';
import type { CurrentUser } from '@/types/auth';

import { LoginForm } from '../components';
import styles from './LoginPage.module.scss';

type Props = {
  redirectLocation: string;
};
const LoginPage = ({ redirectLocation }: Props) => {
  const { theme } = useTheme();
  const { signIn, user } = useStoreUser();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [doLogin, { error, isLoading: loggingUser }] = usePromise(
    login,
    ({ user }: { user: CurrentUser }) => signIn(user)
  );

  useEffect(() => {
    if (user) {
      navigate(redirectLocation);
    }
  }, [user, navigate, redirectLocation]);

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div>
        <LoadingOverlay loading={loggingUser}>
          <div className={styles.wrapper}>
            <LoginForm onSubmit={doLogin} />
            {error && <Error text={t(error)} />}
          </div>
        </LoadingOverlay>
      </div>
    </div>
  );
};

export default LoginPage;
