import React from 'react';

import { useTheme } from '@/hooks';

import { Toolbar } from '../Layout';
import styles from './Layout.module.scss';

type Props = {
  children: React.ReactNode;
  username: string;
};

const Layout = ({ children, username }: Props) => {
  const { theme } = useTheme();

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div className={styles.toolbar}>
        <Toolbar username={username} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default Layout;
