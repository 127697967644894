type FormatTypes = 'YYYY-MM-DD' | 'DD/MM/YYYY HH:MM';

const fillWithZero = (x: number) => (x < 10 ? '0' + x : x);

export const formatDate = (type: FormatTypes, date: string | number | Date) => {
  const dateToFormat = new Date(date);

  switch (type) {
    case 'YYYY-MM-DD':
      return dateToFormat.toISOString().split('T')[0];

    case 'DD/MM/YYYY HH:MM':
      return (
        [
          fillWithZero(dateToFormat.getDate()),
          fillWithZero(dateToFormat.getMonth() + 1),
          fillWithZero(dateToFormat.getFullYear()),
        ].join('/') +
        ' ' +
        [
          fillWithZero(dateToFormat.getHours()),
          fillWithZero(dateToFormat.getMinutes()),
        ].join(':')
      );
  }
};
