import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { CheckCircle, Info, Link } from '@/assets/icons';
import { Error } from '@/components/Error';
import { Spinner } from '@/components/Loading';
import Toolbar from '@/components/Toolbar/Toolbar';
import { usePromise, useTheme, useToaster } from '@/hooks';
import { useVideoSession } from '@/lib/videoStream';
import routes from '@/routes';
import { useStoreUser } from '@/services/auth';
import type { CurrentUser, User } from '@/types/auth';
import { IpCamera } from '@/types/videoConference';

import {
  LowerToolbar,
  UsersToInvite,
  VideoParticipantsList,
} from '../components';
import {
  deviceStatusChanged,
  getConferenceName,
  getNotInvitedUsers,
  getSessionCameras,
  getSessionToken,
  inviteUser,
  kickUser,
  publishCamera,
  unPublishCamera,
} from '../dal';
import styles from './VideoConferencePage.module.scss';

const VideoConferencePage: React.FC = () => {
  const [usersToInvite, setUsersToInvite] = useState<User[]>([]);
  const [ipCameras, setIpCameras] = useState<IpCamera[]>([]);
  const [creatingConnectionToSession, setCreatingConnectionToSession] =
    useState<boolean>(false);
  const [errorCreatingConnection, setErrorCreatingConnection] =
    useState<string>();
  const { t } = useTranslation();
  const onSuffessfullFetch = useCallback(
    ({ cameras }: { cameras: IpCamera[] }) =>
      setIpCameras(cameras.map((c) => ({ ...c, selected: false }))),
    []
  );
  const [conferenceName, setConferenceName] = useState<string | undefined>();
  const [url, setUrl] = useState('');

  const { theme } = useTheme();
  const toast = useToaster();
  const { user } = useStoreUser();
  const { id } = useParams();
  const navigate = useNavigate();

  const [addParticipant] = usePromise(
    inviteUser,
    useCallback(
      ({ userId, sent }: { userId: number; sent: boolean }) => {
        setUsersToInvite((prev) => prev.filter(({ id }) => id !== userId));
        if (sent)
          toast({
            icon: (
              <div className={styles.infoIcon}>
                <CheckCircle />
              </div>
            ),
            type: 'success',
            message: t('Successfully invited user'),
          });
        else
          toast({
            icon: (
              <div className={styles.infoIcon}>
                <Info />
              </div>
            ),
            type: 'info',
            message: t('Joining permissions have been assigned to the user'),
          });
      },
      [t, toast]
    )
  );
  const [loadConferenceName] = usePromise(
    getConferenceName,
    useCallback(
      ({ name }: { name: string | undefined }) => setConferenceName(name),
      []
    )
  );
  const [loadCameras] = usePromise(getSessionCameras, onSuffessfullFetch);
  const [publish] = usePromise(publishCamera);
  const [unPublish] = usePromise(unPublishCamera);
  const [publishDeviceChangedEvent] = usePromise(deviceStatusChanged);
  const [kick] = usePromise(kickUser);
  const [loadFreeUsers] = usePromise(
    getNotInvitedUsers,
    useCallback(({ users }: { users: User[] }) => {
      setUsersToInvite(users);
    }, [])
  );

  const {
    publisher,
    currentDurationTime,
    sessionId,
    participants,
    voiceEnabled,
    cameraEnabled,
    isSharingScreen,
    publishedIpCameraId,
    toggleVoice,
    toggleCamera,
    joinSession,
    leaveSession,
    shareScreen,
    stopSharingScreen,
    onSelectIpCamera,
  } = useVideoSession({
    publishIpCamera: publish,
    unPublishIpCamera: unPublish,
    onDeviceStatusChange: publishDeviceChangedEvent,
    onUserKick: kick,
  });

  useEffect(() => {
    if (sessionId) {
      loadCameras(sessionId);
      loadConferenceName(sessionId);
      loadFreeUsers(sessionId);
    }
  }, [loadCameras, loadConferenceName, loadFreeUsers, sessionId]);

  useEffect(() => {
    if (window) {
      setUrl(window.location.href);
    }
  }, []);

  const joinUserToSession = useCallback(
    (user: CurrentUser, sessionId: string) =>
      getSessionToken(sessionId)
        .then(({ token }) =>
          joinSession(token, { id: user.id, username: user.login })
        )
        .catch((error: any) =>
          setErrorCreatingConnection(error?.message?.toString())
        )
        .finally(() => setCreatingConnectionToSession(false)),
    [joinSession]
  );

  useEffect(() => {
    if (!sessionId && user && id) {
      joinUserToSession(user, id);
    }
  }, [joinUserToSession, user, id, sessionId]);

  const [fethScreenShareToken] = usePromise(getSessionToken, ({ token }) =>
    shareScreen(token)
  );

  const handleLeaveSession = () => {
    leaveSession();
    navigate(routes.CONFERENCES_PAGE);
  };

  const onClickShareScreen = () => {
    if (id) {
      fethScreenShareToken(id, 'screen');
    }
  };

  if (!navigator.cookieEnabled) {
    return (
      <div className={styles.cookies}>
        <b>{t('To use this site enable cookies in browser')}</b>
      </div>
    );
  }

  const isLoading = creatingConnectionToSession;

  if (isLoading) {
    return <Spinner size="large" />;
  }

  if (!sessionId && errorCreatingConnection) {
    return <Error text={t(errorCreatingConnection)} />;
  }

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      {sessionId && (
        <div className={styles.videoView}>
          <Toolbar
            type="sides"
            left={
              <div>
                {currentDurationTime} | {conferenceName}
              </div>
            }
            right={
              <div className={styles.toolbarRight}>
                <div
                  onClick={() => {
                    navigator.clipboard.writeText(url);
                    toast({
                      icon: (
                        <div className={styles.infoIcon}>
                          <Info />
                        </div>
                      ),
                      type: 'info',
                      message: t('Copied to the clipboard!'),
                    });
                  }}
                  className={styles.url}
                >
                  <Link />
                  <p>{url}</p>
                </div>
                {usersToInvite.length ? (
                  <UsersToInvite
                    usersToInvite={usersToInvite}
                    onClick={() =>
                      ({ login, id }: { login: string; id: number }) =>
                        user &&
                        addParticipant({
                          email: login,
                          sessionId,
                          userId: id,
                          invitation: url,
                        })}
                  />
                ) : (
                  <div className={styles.menuMessage}>
                    {t('No possible users to invite')}
                  </div>
                )}
              </div>
            }
          />
          <VideoParticipantsList participants={participants} />
          <LowerToolbar
            participants={participants}
            voiceEnabled={voiceEnabled}
            cameraEnabled={
              publisher?.stream.hasVideo ? cameraEnabled : undefined
            }
            isSharingScreen={isSharingScreen}
            publishedIpCameraId={publishedIpCameraId}
            ipCameras={ipCameras}
            toggleCamera={toggleCamera}
            toggleVoice={toggleVoice}
            onSelectIpCamera={onSelectIpCamera}
            onClickShareScreen={onClickShareScreen}
            stopSharingScreen={stopSharingScreen}
            handleLeaveSession={handleLeaveSession}
          />
        </div>
      )}
    </div>
  );
};

export default VideoConferencePage;
