import { Session } from 'openvidu-browser';
import { useTranslation } from 'react-i18next';

import {
  Camera,
  CameraOff,
  Mic,
  MicOff,
  Monitoring,
  PhoneClosed,
  Tv,
  User,
  XSquare,
} from '@/assets/icons';
import { IconButton } from '@/components/Button';
import { Menu, MenuButton, MenuItem } from '@/components/Menu';
import { PermissionGuard } from '@/components/Permission';
import Toolbar from '@/components/Toolbar/Toolbar';
import { useTheme } from '@/hooks';
import { Participant } from '@/lib/videoStream';
import { IpCamera } from '@/types/videoConference';

import styles from './LowerToolbar.module.scss';

type Props = {
  voiceEnabled: boolean;
  cameraEnabled?: boolean;
  ipCameras: IpCamera[];
  isSharingScreen: boolean;
  publishedIpCameraId?: string;
  participants: Participant[];
  toggleCamera: () => void;
  onClickShareScreen: () => void;
  stopSharingScreen: (sessionToStop?: Session) => void;
  handleLeaveSession: () => void;
  toggleVoice: () => void;
  onSelectIpCamera: (cameraId: string) => void;
};

const LowerToolbar = ({
  voiceEnabled,
  cameraEnabled,
  isSharingScreen,
  publishedIpCameraId,
  ipCameras,
  participants,
  toggleCamera,
  toggleVoice,
  onSelectIpCamera,
  onClickShareScreen,
  stopSharingScreen,
  handleLeaveSession,
}: Props) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  return (
    <Toolbar
      type="middle"
      middle={
        <div className={`${styles.toolbar} ${styles[theme]}`}>
          <Menu
            className={styles.menu}
            direction="top"
            menuButton={
              <MenuButton className={styles.menuButton}>
                <IconButton icon={<User className={styles.primaryIcon} />} />
              </MenuButton>
            }
          >
            {participants.map(({ name }) => (
              <MenuItem className={styles.menuItem} key={name}>
                {name}
              </MenuItem>
            ))}
          </Menu>
          <IconButton
            icon={
              voiceEnabled ? (
                <Mic className={styles.primaryIcon} />
              ) : (
                <MicOff className={styles.primaryIcon} />
              )
            }
            onClick={toggleVoice}
          />
          {cameraEnabled !== undefined ? (
            <IconButton
              icon={
                cameraEnabled ? (
                  <Camera className={styles.primaryIcon} />
                ) : (
                  <CameraOff className={styles.primaryIcon} />
                )
              }
              onClick={toggleCamera}
            />
          ) : (
            <IconButton
              title={t('Camera not found')}
              disabled
              icon={<CameraOff className={styles.primaryIcon} />}
            />
          )}

          {isSharingScreen ? (
            <IconButton
              icon={<XSquare className={styles.primaryIcon} />}
              onClick={() => stopSharingScreen()}
            />
          ) : (
            <IconButton
              icon={<Tv className={styles.primaryIcon} />}
              onClick={onClickShareScreen}
            />
          )}
          <PermissionGuard permission="CONFERENCE_APP_RTSP_STREAM_SHARING">
            <Menu
              menuButton={
                <MenuButton className={styles.menuButton}>
                  <IconButton
                    icon={<Monitoring className={styles.primaryIcon} />}
                  />
                </MenuButton>
              }
            >
              {ipCameras
                .filter(({ id }) => id !== publishedIpCameraId)
                .map(({ id, name }) => (
                  <MenuItem
                    className={styles.menuItem}
                    key={id}
                    value={id}
                    onClick={() => onSelectIpCamera(id)}
                  >
                    {name}
                  </MenuItem>
                ))}
            </Menu>
          </PermissionGuard>
          <IconButton
            icon={<PhoneClosed className={styles.redIcon} />}
            onClick={handleLeaveSession}
          />
        </div>
      }
    ></Toolbar>
  );
};

export default LowerToolbar;
