import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
} from 'react-router-dom';

import {
  ConferencesPage,
  JoinConferencePage,
  LoginPage,
  VideoConferencePage,
} from '@/features/VideoConference';
import { CurrentUser } from '@/types/auth';

import routes from './routes';
import { logout, useStoreUser } from './services/auth';

const App = () => {
  useEffect(() => {
    return () => {
      logout();
    };
  }, []);

  const { user } = useStoreUser();
  const [lastUnauthorizedLocation, setLastUnauthorizedLocation] =
    useState<string>();

  const router = createBrowserRouter([
    {
      path: routes.LOGIN_PAGE,
      element: (
        <LoginPage
          redirectLocation={lastUnauthorizedLocation || routes.CONFERENCES_PAGE}
        />
      ),
    },
    {
      path: routes.JOIN_CONFERENCE_PAGE(':id'),
      element: (
        <ProtectedRoute
          user={user}
          onUnauthorizedLocation={setLastUnauthorizedLocation}
        >
          <JoinConferencePage />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.CONFERENCES_PAGE,
      element: (
        <ProtectedRoute
          user={user}
          onUnauthorizedLocation={setLastUnauthorizedLocation}
        >
          <ConferencesPage
            username={user?.displayName || (user?.login as string)}
          />
        </ProtectedRoute>
      ),
    },
    {
      path: routes.CONFERENCE_PAGE(':id'),
      element: (
        <ProtectedRoute
          user={user}
          onUnauthorizedLocation={setLastUnauthorizedLocation}
        >
          <VideoConferencePage />
        </ProtectedRoute>
      ),
    },
    {
      path: '*',
      element: <Navigate to={routes.LOGIN_PAGE} />,
    },
  ]);
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
};

const ProtectedRoute = ({
  user,
  path,
  children,
  onUnauthorizedLocation,
}: {
  user?: CurrentUser;
  path?: string;
  children: React.ReactElement;
  onUnauthorizedLocation?: (location: string) => void;
}) => {
  const prevRoute = useLocation();

  useEffect(() => {
    if (!user) {
      onUnauthorizedLocation?.(prevRoute.pathname);
    }
  }, [onUnauthorizedLocation, user, prevRoute]);

  if (!user) {
    return <Navigate to={path || routes.LOGIN_PAGE} />;
  }

  return children;
};
export default App;
