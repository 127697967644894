import { useTranslation } from 'react-i18next';

import { ChevronDown, Lock, User } from '@/assets/icons/index';
import logo from '@/assets/logo/logo.svg';
import { usePromise, useTheme } from '@/hooks';
import { logout, useStoreUser } from '@/services/auth';

import { LinkButton } from '../Button';
import { Menu, MenuButton, MenuItem } from '../Menu';
import styles from './Toolbar.module.scss';

type Props = {
  username: string;
};

const Toolbar = ({ username }: Props) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { onLogout } = useStoreUser();

  const [doLogout] = usePromise(logout, onLogout);

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <div>
        <img src={logo} alt="logo" />
      </div>
      <div>
        <Menu
          menuButton={
            <MenuButton className={styles.menuButton}>
              <LinkButton
                iconBefore={
                  <div className={styles.userIcon}>
                    <User />
                  </div>
                }
                iconAfter={
                  <div className={styles.chevronIcon}>
                    <ChevronDown />
                  </div>
                }
              >
                {username}
              </LinkButton>
            </MenuButton>
          }
        >
          <MenuItem onClick={doLogout} className={styles.option}>
            <div className={styles.lockIcon}>
              <Lock />
            </div>
            {t('Log out')}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Toolbar;
