import { LanguageDetectorModule } from 'i18next';

import { Language } from './d';

const detectLanguage = (): Language => {
  try {
    return (localStorage.getItem('language') as Language) || 'pl';
  } catch (err) {
    console.log(err);
  }
  return 'pl';
};

const detector: LanguageDetectorModule = {
  type: 'languageDetector',
  init: () => {},
  cacheUserLanguage: () => {},
  detect: () => detectLanguage(),
};

export default detector;
