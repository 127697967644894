const Monitoring = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_229_4430)">
      <path
        d="M22.3 9.39997L22.6 8.64997C22.7 8.39997 22.6 8.09997 22.35 7.99997L7.30002 1.34997C7.05002 1.24997 6.75002 1.34997 6.65002 1.59997L4.10002 7.34997C4.05002 7.44997 4.05002 7.59997 4.10002 7.74997C4.15002 7.84997 4.25002 7.94997 4.35002 8.04997L11.9 11.4C11.75 12.45 12.75 13.15 11.95 14.65C11.5 15.5 10.6 16 9.65002 16H7.45002C6.70002 14.15 4.90002 12.9 2.90002 12.9H1.15002C0.850024 12.9 0.650024 13.1 0.650024 13.4V22.15C0.650024 22.45 0.850024 22.65 1.15002 22.65H2.90002C7.50002 22.4 8.00002 18.05 7.70002 16.95H9.60002C10.95 16.95 12.15 16.2 12.8 15.05C13.65 13.5 13 12.25 12.85 11.75L19.4 14.65C19.7 14.75 20 14.6 20.05 14.4L20.35 13.7C21.7 13.95 22.7 13.2 23.05 12.3C23.25 12.05 23.8 10.65 22.3 9.39997ZM2.95002 21.7H1.65002V13.95H2.90002C5.00002 13.95 6.75002 15.65 6.80002 17.8C6.80002 19.9 5.10002 21.7 2.95002 21.7ZM19.35 13.6L12.65 10.65L5.20002 7.34997L7.35002 2.49997L21.5 8.74997L19.35 13.6ZM22.2 11.95C21.95 12.5 21.4 12.8 20.8 12.8L21.85 10.4C22.55 11 22.3 11.75 22.2 11.95Z"
        fill="#3377FF"
        stroke="#3377FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_229_4430">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Monitoring;
