import React, { useCallback, useEffect, useState } from 'react';

import {
  IpCameraStream,
  Participant,
  VideoStreamComponent,
} from '@/lib/videoStream';

import styles from './VideoParticipantsList.module.scss';

type Props = {
  participants: Participant[];
};

const VideoParticipantsList: React.FC<Props> = ({ participants }) => {
  const findMainScreen = useCallback(
    () =>
      participants.find((p) => p.type === 'screen') ||
      participants.find((p) => p.hasVideo),
    [participants]
  );

  const [mainScreen, setMainScreen] = useState(findMainScreen());

  useEffect(() => {
    setMainScreen(findMainScreen());
    // preventing from infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [participants.length]);

  const otherParticipants = participants.filter((p) =>
    mainScreen ? p.name !== mainScreen.name : true
  );

  if (!mainScreen) {
    return (
      <div className={styles.participantsWrapper}>
        {participants.map((item, index) => (
          <div
            className={styles.participantItem}
            onDoubleClick={() => setMainScreen(item)}
            key={index}
          >
            {item.type === 'IPCAM' ? (
              <IpCameraStream participant={item} />
            ) : (
              <VideoStreamComponent participant={item} />
            )}
          </div>
        ))}
      </div>
    );
  }

  if (participants.length === 1)
    return (
      <div
        className={styles.mainScreen}
        onDoubleClick={() => setMainScreen(undefined)}
      >
        <VideoStreamComponent
          participant={
            participants.find((p) => p.name === mainScreen.name) as Participant
          }
        />
      </div>
    );

  return (
    <div className={styles.allParticipantsWrapper}>
      <div className={styles.mainScreen}>
        <VideoStreamComponent
          participant={
            // when passing mainScreen - VideoStreamComponent is not getting updated about isSpeacking property
            participants.find((p) => p.name === mainScreen.name) as Participant
          }
        />
      </div>
      <div className={styles.sideParticipantsWrapper}>
        {otherParticipants.map((item, index) => (
          <div
            className={styles.listItem}
            onDoubleClick={() => setMainScreen(item)}
            key={index}
          >
            {item.type === 'IPCAM' ? (
              <IpCameraStream participant={item} />
            ) : (
              <VideoStreamComponent participant={item} />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoParticipantsList;
