import { parse } from '@/lib/querystring';
import type { QueryParams } from '@/lib/querystring/d';

type Signals = Record<string, AbortController>; // klucz metoda:url

type Config = {
  cancelable: boolean;
};

const api = () => {
  const BASE_URL = process.env.REACT_APP_SERVER_URL;
  const DEFAULT_CONFIG = { cancelable: false };

  const signals: Signals = {};

  const initRequestOptions = <TBody>(
    additionalHeaders: Record<string, string> = {},
    body?: TBody
  ) => ({
    ...additionalHeaders,
    credentials: 'include' as 'include',
    headers: { 'Content-Type': 'application/json' },
    body: body && JSON.stringify(body),
  });

  const get = <TResponse = any>(
    url: string,
    queryParams?: QueryParams,
    config: Config = DEFAULT_CONFIG
  ) => {
    const requestOptions = initRequestOptions({ method: 'GET' });

    const targetUrl = queryParams ? `${url}?${parse(queryParams)}` : url;

    return invokeCall<TResponse>(targetUrl, requestOptions, config);
  };

  const post = <TResponse = any, TBody = any>(
    url: string,
    body?: TBody,
    config: Config = DEFAULT_CONFIG
  ) => {
    const requestOptions = initRequestOptions({ method: 'POST' }, body);

    return invokeCall<TResponse>(url, requestOptions, config);
  };

  const patch = <TResponse = any, TBody = any>(
    url: string,
    body?: TBody,
    config: Config = DEFAULT_CONFIG
  ) => {
    const requestOptions = initRequestOptions({ method: 'PATCH' }, body);

    return invokeCall<TResponse>(url, requestOptions, config);
  };

  // prefixed with underscore because delete is a reserved word in javascript
  const _delete = <TResponse = any, TBody = any>(
    url: string,
    body?: TBody,
    config: Config = DEFAULT_CONFIG
  ) => {
    const requestOptions = initRequestOptions({ method: 'DELETE' }, body);

    return invokeCall<TResponse>(url, requestOptions, config);
  };

  // helper functions
  const invokeCall = <TResponse>(
    url: string,
    requestOptions: Record<string, unknown>,
    config: Config
  ) => {
    const { cancelable } = config;

    const fullUrl = url.startsWith('/')
      ? BASE_URL + url.slice(1)
      : BASE_URL + url;

    if (!cancelable) {
      return fetch(fullUrl, { ...requestOptions }).then((res) =>
        handleResponse<TResponse>(res)
      );
    }

    const controller = new AbortController();
    const signal = controller.signal;

    const previousPendingRequest =
      signals[`${requestOptions.method}${fullUrl}`];

    if (previousPendingRequest) {
      previousPendingRequest.abort();
    }

    signals[`${requestOptions.method}${fullUrl}`] = controller;

    return fetch(fullUrl, { ...requestOptions, signal }).then((res) =>
      handleResponse<TResponse>(res)
    );
  };

  const handleResponse = <TResponse>(res: Response): Promise<TResponse> => {
    if (!res.ok) {
      return res.json().then(({ message }) => {
        throw new Error(message || res.statusText);
      });
    }

    return res.json();
  };

  return {
    get,
    post,
    patch,
    delete: _delete,
  };
};

export const dal = api();
