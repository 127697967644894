import { useContext } from 'react';

import { ThemeContext } from './ThemeProvider';

const useTheme = () => {
  const context = useContext(ThemeContext);

  if (context === undefined) {
    throw new Error('Cannot use useTheme without provider');
  }

  return context;
};

export default useTheme;
