import React from 'react';

import { useTheme } from '@/hooks';

import styles from './LinkButton.module.scss';

type Props = React.ComponentPropsWithRef<'button'> & {
  children: React.ReactNode;
  iconBefore?: React.ReactNode;
  iconAfter?: React.ReactNode;
};

const LinkButton = ({
  children,
  iconAfter,
  iconBefore,
  ...passThroughProps
}: Props) => {
  const { theme } = useTheme();

  return (
    <button
      {...passThroughProps}
      className={`${styles.button} ${styles[theme]}`}
    >
      <div>{iconBefore}</div>
      {children}
      <div>{iconAfter}</div>
    </button>
  );
};

export default LinkButton;
