import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationPl from '../../assets/localizations/pl.json';
import detector from './detector';

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: false, // key is being the fallback (thus there are no en translations)
    returnNull: false,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      pl: {
        translation: translationPl,
      },
      en: {
        translation: {},
      },
    },
  });
