import React from 'react';

import { Variants } from '../../hooks/ToasterProvider';
import styles from './Toaster.module.scss';

type Props = {
  message: string;
  type: Variants;
  icon?: React.ReactNode;
};

const Toaster = ({ icon, message, type }: Props) => (
  <div className={`${styles[type]} ${styles.toaster}`}>
    {icon}
    <p>{message}</p>
  </div>
);

export default Toaster;
