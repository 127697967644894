import { useContext } from 'react';

import { ToasterContext } from './ToasterProvider';

const useToaster = () => {
  const context = useContext(ToasterContext);

  if (!context) {
    throw new Error('Cannot use useToaster without provider');
  }

  return context;
};

export default useToaster;
