import React, { useEffect, useRef } from 'react';

import { Times } from '@/assets/icons';
import { IconButton } from '@/components/Button';
import { LoadingOverlay } from '@/components/Loading';
import { PermissionGuard } from '@/components/Permission';
import { useTheme } from '@/hooks';

import { Participant } from '../d';
import styles from './IpCameraStream.module.scss';
import Tag from './Tag';

type Props = {
  participant: Participant;
};

const IpCameraStream: React.FC<Props> = ({ participant }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const { theme } = useTheme();
  const { streamManager, name, onCloseConnectionClick } = participant;

  useEffect(() => {
    if (streamManager && videoRef.current) {
      streamManager.addVideoElement(videoRef.current);
    }
  }, [streamManager]);

  const connectionEstablished =
    videoRef.current &&
    !videoRef.current.paused &&
    videoRef.current.readyState > 2; //https://stackoverflow.com/questions/6877403/how-to-tell-if-a-video-element-is-currently-playing

  return (
    <div className={`${styles.box} ${styles[theme]}`}>
      <LoadingOverlay loading={!connectionEstablished}>
        <div className={styles.video}>
          <div className={styles.actionsButton}>
            <PermissionGuard permission="CONFERENCE_APP_RTSP_STREAM_CLOSE">
              <IconButton
                icon={<Times className={styles.primaryIcon} />}
                onClick={onCloseConnectionClick}
              />
            </PermissionGuard>
          </div>
          <video className={styles.videoBox} autoPlay={true} ref={videoRef} />
          <div className={styles.bottomTag}>
            <Tag>{name}</Tag>
          </div>
        </div>
      </LoadingOverlay>
    </div>
  );
};

export default IpCameraStream;
