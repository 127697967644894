import React from 'react';

import { useTheme } from '@/hooks';

import styles from './Tag.module.scss';

type Props = {
  children: React.ReactNode;
};

const Tag: React.FC<Props> = ({ children }) => {
  const { theme } = useTheme();

  return <div className={`${styles.tag} ${styles[theme]}`}>{children}</div>;
};

export default Tag;
