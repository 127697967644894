import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { PrimaryButton, SecondaryButton } from '@/components/Button';
import TextInput from '@/components/Input/TextInput';
import Table from '@/components/Table/Table';
import { useTheme } from '@/hooks';
import routes from '@/routes';
import { VideoSession } from '@/types/videoConference';

import styles from './Conferences.module.scss';

type Props = {
  conferences: VideoSession[];
};

const Conferences = ({ conferences }: Props) => {
  const { t } = useTranslation();
  const { theme } = useTheme();
  const [filteredConferences, setFilteredConferences] = useState(conferences);

  const navigate = useNavigate();

  const redirectToConferenceJoinPage = (conferenceId: string) => {
    navigate(routes.CONFERENCE_PAGE(conferenceId));
  };

  const columns = [
    { name: 'name', label: t('Name') },
    { name: 'ddtm', label: t('Time') },
    {
      name: 'actions',
      label: '',
      render: (item: VideoSession) => (
        <SecondaryButton onClick={() => redirectToConferenceJoinPage(item.id)}>
          {t('Join to conference')}
        </SecondaryButton>
      ),
    },
  ];

  const submitFilters = (
    e: React.FormEvent<HTMLFormElement> & {
      target: {
        name: {
          value: string;
        };
        ddtm: {
          value: string;
        };
      };
    }
  ) => {
    e.preventDefault();

    const obj = {
      name: e.target.name && e.target.name.value ? e.target.name.value : '',
      ddtm: e.target.ddtm && e.target.ddtm.value ? e.target.ddtm.value : '',
    };

    setFilteredConferences(
      conferences.filter((conference) =>
        Object.entries(obj).every(([key, value]) =>
          value
            ? conference[key as keyof VideoSession] &&
              conference[key as keyof VideoSession]
                .toString()
                .toLowerCase()
                .includes(value.toString().toLowerCase())
            : true
        )
      )
    );
  };

  return (
    <div className={`${styles.container} ${styles[theme]}`}>
      <form onSubmit={submitFilters} className={styles.filters}>
        <TextInput name="name" placeholder={t('Name')} />
        <TextInput name="ddtm" placeholder={t('Time')} />
        <PrimaryButton type="submit">{t('Filter')}</PrimaryButton>
      </form>
      {filteredConferences.length ? (
        <Table columns={columns} items={filteredConferences} />
      ) : (
        <div className={styles.message}>
          {t('No conferences for those filters')}
        </div>
      )}
    </div>
  );
};

export default Conferences;
