import React, { useState } from 'react';

import { Toaster } from '../components/Toaster';
import styles from './Toaster.module.scss';

export type Variants = 'info' | 'success' | 'error' | 'warning' | '';

type Toast = {
  type: Variants;
  message: string;
  icon?: React.ReactNode;
};

type Props = {
  children: React.ReactNode;
};

export const ToasterContext = React.createContext<
  ((args: Toast) => void) | null
>(null);

const ToasterProvider = ({ children }: Props) => {
  const [toaster, setToaster] = useState<
    {
      type: Variants;
      message: string;
      icon?: React.ReactNode;
      id: number;
    }[]
  >([]);

  const clearToaster = (id: number) => {
    setToaster((prev) => prev.filter((toast) => id !== toast.id));
  };

  const toast = ({ type, message, icon }: Toast) => {
    const id = Date.now();
    setToaster((prev) => [...prev, { type, message, icon, id }]);

    setTimeout(() => {
      clearToaster(id);
    }, 3000);
  };

  return (
    <ToasterContext.Provider value={toast}>
      {children}
      <div className={styles.toasterWrapper}>
        {toaster.map((toast) => (
          <div key={toast.id} className={styles.toaster}>
            <Toaster
              message={toast.message}
              type={toast.type}
              icon={toast.icon}
            />
          </div>
        ))}
      </div>
    </ToasterContext.Provider>
  );
};

export default ToasterProvider;
