import './index.css';
import './lib/translation/init';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import { ThemeProvider, ToasterProvider } from './hooks';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './services/auth';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <UserProvider>
      <ThemeProvider>
        <ToasterProvider>
          <App />
        </ToasterProvider>
      </ThemeProvider>
    </UserProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
