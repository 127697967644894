import React from 'react';

import { useTheme } from '@/hooks';

import { Column } from './d';
import styles from './Table.module.scss';

type Props<T> = {
  columns: Column<T>[];
  items: T[];
};

const Table = <T extends Record<string, React.ReactNode>>({
  items,
  columns,
}: Props<T>) => {
  const { theme } = useTheme();

  return (
    <table className={`${styles.table} ${styles[theme]}`}>
      <thead>
        <tr>
          {columns.map((column) => (
            <th key={column.name}>{column.label}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => (
          <tr className={styles.row} key={index}>
            {columns.map((column) =>
              column.render ? (
                <td key={column.name + index}>{column.render(item)}</td>
              ) : (
                <td key={column.name + index}>{item[column.name]}</td>
              )
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
