import { useEffect, useState } from 'react';

function useQueryParams<T>(): T | undefined {
  const [params, setParams] = useState<T>();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setParams(Object.fromEntries(urlParams.entries()) as T);
  }, []);

  return params;
}
export default useQueryParams;
