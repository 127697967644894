import React from 'react';
import { useTranslation } from 'react-i18next';

import { MoreVertical } from '@/assets/icons';
import { IconButton } from '@/components/Button';
import { Menu, MenuButton, MenuItem } from '@/components/Menu';
import { useTheme } from '@/hooks';
import { useStoreUser } from '@/services/auth';

import styles from './ContextMenu.module.scss';

type Directions = 'top' | 'bottom' | 'left' | 'right';

type Props = {
  direction?: Directions;
  onMute: () => void;
  onKick: () => void;
};

const ContextMenu: React.FC<Props> = ({
  direction = 'bottom',
  onMute,
  onKick,
}) => {
  const { t } = useTranslation();
  const { theme } = useTheme();

  const { hasPermission } = useStoreUser();
  const canKick = hasPermission?.('CONFERENCE_APP_KICK_USER') || false;
  const canMute = hasPermission?.('CONFERENCE_APP_MUTE_USER') || false;

  const options = [
    {
      label: t('Mute user'),
      isAllowed: canMute,
      onClick: onMute,
    },
    {
      label: t('Kick user'),
      isAllowed: canKick,
      onClick: onKick,
      color: 'red',
    },
  ];

  const userPermissions = options.filter(({ isAllowed }) => isAllowed);

  if (userPermissions.length > 0) {
    return (
      <Menu
        className={`${styles.menu} ${styles[theme]}`}
        direction={direction}
        menuButton={
          <MenuButton className={styles.menuButton}>
            <IconButton
              icon={<MoreVertical className={styles.primaryIcon} />}
            />
          </MenuButton>
        }
      >
        {options
          .filter(({ isAllowed }) => isAllowed)
          .map(({ label, color, onClick }) => (
            <MenuItem
              key={label}
              onClick={onClick}
              className={`${styles.menuItem} ${
                color ? styles[color] : undefined
              }`}
            >
              {label}
            </MenuItem>
          ))}
      </Menu>
    );
  }

  return <></>;
};

export default ContextMenu;
