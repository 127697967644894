import React, { useEffect, useState } from 'react';

type Themes = 'dark' | 'light';

type Props = {
  children: React.ReactNode;
};

export const ThemeContext = React.createContext<
  | {
      theme: Themes;
      switchTheme: () => void;
    }
  | undefined
>(undefined);

const ThemeProvider = ({ children }: Props) => {
  const [theme, setTheme] = useState<Themes>('dark');

  useEffect(
    () => setTheme((localStorage.getItem('theme') as Themes) || 'dark'),
    []
  );

  const switchTheme = () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';

    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, switchTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeProvider;
