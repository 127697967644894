import React from 'react';

import styles from './Spinner.module.scss';

type Props = {
  size: 'small' | 'medium' | 'large';
};

const Spinner: React.FC<Props> = ({ size = 'medium' }) => {
  const resolveSizeClassName = (size: 'small' | 'medium' | 'large') => {
    switch (size) {
      case 'small':
        return styles['loader-small'];
      case 'medium':
        return styles['loader-medium'];
      case 'large':
        return styles['loader-large'];
    }
  };

  return <span className={`${styles.loader} ${resolveSizeClassName(size)}`} />;
};

export default Spinner;
