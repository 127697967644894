import type { QueryParams } from './d';

const parse = (params: QueryParams) => {
  const parsed = Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value.toString(),
    }),
    {}
  );

  return new URLSearchParams(parsed).toString();
};

export default parse;
