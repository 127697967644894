import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';

import { CurrentUser, Permission } from '@/types/auth';

import checkPermissions from '../checkPermissions';

const UserContext = createContext<
  | {
      user?: CurrentUser;
      hasPermission: ((permission: Permission) => boolean) | undefined;
      signIn: (user: CurrentUser) => void;
      onLogout: () => void;
    }
  | undefined
>(undefined);

type Props = {
  children: ReactNode;
};

const UserProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<CurrentUser>();

  const handleSetUser = (user: CurrentUser) => {
    setUser(user);
  };

  const hasPermission = useCallback(() => {
    if (user?.permissions) {
      return checkPermissions(user.permissions);
    }
  }, [user?.permissions]);

  const clearUser = () => {
    setUser(undefined);
  };

  return (
    <UserContext.Provider
      value={{
        user,
        signIn: handleSetUser,
        hasPermission: hasPermission(),
        onLogout: clearUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

const useStoreUser = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('context error');
  }

  return context;
};

export { UserProvider, useStoreUser };
