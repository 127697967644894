import React from 'react';

import { useTheme } from '@/hooks';

import styles from './PrimaryButton.module.scss';

type Props = React.ComponentPropsWithRef<'button'> & {
  children: React.ReactNode;
};

const PrimaryButton = ({ children, ...passThroughProps }: Props) => {
  const { theme } = useTheme();

  return (
    <button
      {...passThroughProps}
      className={`${styles.button} ${styles[theme]}`}
    >
      {children}
    </button>
  );
};

export default PrimaryButton;
