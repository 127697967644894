import React, { useEffect, useRef } from 'react';

import { MicOff } from '@/assets/icons';
import { useTheme } from '@/hooks';

import { Participant } from '../d';
import ContextMenu from './ContextMenu';
import Tag from './Tag';
import styles from './VideoComponent.module.scss';

type Props = {
  participant: Participant;
};

const VideoStream: React.FC<Props> = ({ participant }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const { theme } = useTheme();

  useEffect(() => {
    if (participant && participant.streamManager && !!videoRef.current) {
      participant.streamManager.addVideoElement(videoRef.current);
    }
  }, [participant]);

  const isMuted = !participant?.streamManager.stream.audioActive;

  return (
    <div className={`${styles.box} ${styles[theme]}`}>
      {participant !== undefined ? (
        <div className={styles.video}>
          <div className={styles.actionsButton}>
            <ContextMenu
              direction="left"
              onMute={participant.onMuteClick}
              onKick={participant.onCloseConnectionClick}
            />
          </div>
          <div className={styles.topTag}>
            {isMuted && (
              <Tag>
                <MicOff className={styles.primaryIcon} />
              </Tag>
            )}
          </div>
          {participant.hasVideo ? (
            <video
              className={`${styles.box} ${styles.videoActive} ${
                participant.isSpeaking && styles.active
              }`}
              autoPlay={true}
              ref={videoRef}
            />
          ) : (
            <div
              className={`${styles.box} ${styles.noVideo} ${
                participant.isSpeaking && styles.active
              }`}
            >
              <span>{participant.name}</span>
              <audio autoPlay={true} ref={videoRef}></audio>
            </div>
          )}
          {participant.hasVideo && (
            <div className={styles.bottomTag}>
              <Tag>{participant.name}</Tag>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default VideoStream;
