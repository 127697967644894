import React from 'react';

import { useStoreUser } from '@/services/auth';
import { Permission } from '@/types/auth';

type Props = {
  permission: Permission;
  children: React.ReactElement;
  noAccess?: React.ReactElement;
};

const PermissionGuard: React.FC<Props> = ({
  permission,
  noAccess,
  children,
}) => {
  const { hasPermission } = useStoreUser();
  const hasAccess = hasPermission?.(permission) || !permission;

  if (hasAccess) {
    return children;
  }

  return noAccess || <></>;
};

export default PermissionGuard;
